


import { Vue, Watch, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import DateTimeHelper from '@/_helpers/date-time.helper';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TMeeting, TTimeSlot } from '@/_types/meeting/meeting.type';
import { TContact } from '@/_types/contact.type';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TNotification } from '@/_modules/promo/types/notification.type';
import { TOpenEwSharerPayload } from '@/_store/ew-sharer.store';
import { TScheduleDay } from '@/_modules/ew-calendar/types/ew-calendar.type';
import IconMeetingAccept from '@/_modules/icons/components/meetings/icon-meeting-accept.vue';
import IconMeetingPlus from '@/_modules/icons/components/meetings/icon-meeting-plus.vue';
import IconMeetingReject from '@/_modules/icons/components/meetings/icon-meeting-reject.vue'; // TODO: remove
import IconMeetingShare from '@/_modules/icons/components/meetings/icon-meeting-share.vue';
import IconMeetingStar from '@/_modules/icons/components/meetings/icon-meeting-star.vue';
import IconMeetingStart from '@/_modules/icons/components/meetings/icon-meeting-start.vue';
import AddToCalendar from '@/_components/add-to-calendar/add-to-calendar.vue';
import eventDiscoveryService from '@/_services/event-discovery.service';
import MeetingsRequests from '@/_modules/meetings/components/meetings-requests/meetings-requests.vue';
import EwCalendar from '@/_modules/ew-calendar/ew-calendar.vue';
import EwIconLockUnlock from '@/_modules/icons/components/meetings/ew-icon-lock-unlock.vue';
import IconVideoCall from '@/_modules/icons/components/sidebar/icon-video-call.vue';
import EwIconAcceptReject from '@/_modules/icons/components/meetings/ew-icon-accept-reject.vue';

import Person from '@/_modules/contacts/components/person/person.vue';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import TimeSlotMenu from '@/_modules/meetings/components/time-slot-menu/time-slot-menu.vue';
import {TShowTimeSlotMenuPayload} from '@/_modules/meetings/store/meetings.store';
import { mapState } from 'vuex';

@Component({
  components: {
    MeetingsRequests,
    AddToCalendar,
    IconMeetingAccept,
    IconMeetingPlus,
    IconMeetingReject,
    IconMeetingShare,
    IconMeetingStar,
    IconMeetingStart,
    EwCalendar,
    EwIconLockUnlock,
    IconVideoCall,
    EwIconAcceptReject,
    Person,
    TimeSlotMenu,
  },
  computed: {
    ...mapState('meetingsStore', {
      meetingsByUserId: 'meetingsByUserId'
    })
  }
})

export default class Meetings extends Vue {

  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName: string }) => void;
  @Getter('ewSharerStore/urlToShare') urlToShare: string;

  @Action('meetingsStore/showTimeSlotMenu') showTimeSlotMenu: (payload: TShowTimeSlotMenuPayload) => void;

  @Getter('_userStore/user') user: TUser;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') contact: TContact;
  @Getter('notificationsStore/meetingsCount') meetingsCount: number;
  @Getter('notificationsStore/noticedMeetingsCount') noticedMeetingsCount: number; // TODO: unused?
  @Getter('notificationsStore/popupNotifications') notifications: TNotification[];
  @Getter('meetingsStore/getMeetingsByUserId') getMeetingsByUserId: (userId: number) => TMeeting[];

  public selectedDate: Date = null;
  public timeSlots: TTimeSlot[] = [];
  public nowTimestamp: number = (new Date()).getTime();
  public nowTimestampUpdaterIntervalId: number = null;
  public timeSlotForDotsMenu: TTimeSlot = null;
  public readonly meetingsByUserId: { [userId: number]: TMeeting[] };

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get userId(): number {
    return (this.user && this.user.id) || null;
  }

  public get contactId(): number {
    return (this.contact && this.contact.id) || null;
  }

  public get getConfirmedMeetings(): TMeeting[] {
    if (!this.user) {
      return [];
    }

    return this.getMeetingsByUserId(this.userId).filter((item: TMeeting) => {
      return item.status === MeetingStatus.Confirmed;
    });
  }

  public get selectedDayMeetingList(): { [key: string]: TMeeting } {
    if (!this.selectedDate) {
      return {};
    }

    const confirmedMeetings = (this.getConfirmedMeetings || []);
    if (!confirmedMeetings.length) {
      return {};
    }

    const filterDate: string = DateTimeHelper.getDateWithoutHoursMinutes(this.selectedDate);

    return confirmedMeetings
      .filter(meeting => {
        return filterDate === DateTimeHelper.getDateWithoutHoursMinutes(meeting.date_start);
      })
      .reduce((acc: { [key: string]: TMeeting }, meeting: TMeeting) => {
        acc[DateTimeHelper.getFullDate(meeting.date_start)] = meeting;
        return acc;
      }, {});
  }

  public get timeSlotsPastTimeMark(): number {
    // Current time - 30 minutes, as specified in AW-1779
    // See isTimeSlotPast for comparison
    return this.nowTimestamp - (1000 * 60 * 30);
  }

  public set timeSlotsPastTimeMark(value: number) {
    this.nowTimestamp = value;
  }

  public get formattedSelectedDate(): string {
    return this.selectedDate && this.$moment(this.selectedDate).format(DateTimeFormat.DAY_NAME_DATE_MONTH_UK);
  }

  public get legendDate(): string {
    const today: Date = new Date();
    return today.getDate().toFixed(0).padStart(2, '0');
  }

  public get legendHoursMinutes(): string {
    const today: Date = new Date();
    const hours: string = today.getHours().toFixed(0).padStart(2, '0');
    const minutes: string = (today.getMinutes() < 30) ? '00' : '30';
    return hours + ':' + minutes;
  }

  @Watch('meetingsByUserId')
  private onMeetingsByUserIdChanged(): void {
    this.createTimeSlots();
  }

  @Watch('event', { immediate: true })
  private onEventChanged(): void {
    if (!this.event) {
      return;
    }
    this.createTimeSlots();
  }

  @Watch('user', { immediate: true })
  private onUserChanged(): void {
    if (!this.userId) {
      return;
    }
    this.$store.dispatch('meetingsStore/requestUserMeetings', { userId: this.userId, force: true });
  }

  @Watch('selectedDate')
  private onSelectedDateChanged(): void {
    this.createTimeSlots();
  }

  @Watch('meetingsCount')
  private onMeetingsCountChanged(): void {
    if (!this.user) {
      return;
    }
    this.$store.dispatch('meetingsStore/requestUserMeetings', {
      userId: this.userId,
      force: true,
    });
    this.createTimeSlots();
  }

  public mounted(): void {
    this.chooseToday();
    this.startNowTimestampUpdate();
  }

  public beforeDestroy(): void {
    this.stopNowTimestampUpdate();
  }

  public chooseToday(): void {
    const today: Date = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    this.selectedDate = today;
  }

  private startNowTimestampUpdate(): void {
    this.nowTimestampUpdaterIntervalId = window.setInterval(() => {
      this.timeSlotsPastTimeMark = (new Date()).getTime();
    }, 1000 * 60);
  }

  private stopNowTimestampUpdate(): void {
    window.clearInterval(this.nowTimestampUpdaterIntervalId);
  }

  private isTimeSlotMeetingCurrent(timeSlot: TTimeSlot): boolean {
    if (!timeSlot.meeting) {
      return false;
    }

    return timeSlot.meeting.date_start.getTime() < this.nowTimestamp
      && timeSlot.meeting.date_end.getTime() > this.nowTimestamp;
  }

  public createTimeSlots(): void {
    this.timeSlots = [];
    const event = this.event;
    if (
      !this.selectedDate
      || !event
      || !event.date_start
      || !event.date_end
    ) {
      return;
    }

    const eventDateStartMoment = this.$moment(event.date_start);
    const eventDateEndMoment = this.$moment(event.date_end);
    if (eventDateStartMoment.isSameOrAfter(eventDateEndMoment)) {
      return;
    }

    const selectedDayMeetingList = this.selectedDayMeetingList;
    const selectedDateMoment = this.$moment(this.selectedDate);
    let iterateTimeMoment = selectedDateMoment.clone()
      .hours(0).minutes(0).seconds(0).milliseconds(0);
    const endTimeMoment = iterateTimeMoment.clone().add(1, 'day');
    const uniqueDates: { [dateKey: string]: boolean } = {};

    while (iterateTimeMoment.isBefore(endTimeMoment)) {
      const currentTimeDateKey = DateTimeHelper.getFullDate(iterateTimeMoment.toDate());
      const nextTimeMoment = iterateTimeMoment.clone().add(30, 'minutes');
      if (uniqueDates[currentTimeDateKey]) {
        iterateTimeMoment = nextTimeMoment;
        continue;
      }

      this.timeSlots.push({
        dateStart: iterateTimeMoment.toDate(),
        dateEnd: nextTimeMoment.toDate(),
        meeting: selectedDayMeetingList[currentTimeDateKey] || null,
      });

      uniqueDates[currentTimeDateKey] = true;
      iterateTimeMoment = nextTimeMoment;
    }
  }

  public async markSlotNotAvailable(timeSlot: TTimeSlot): Promise<void> {
    const dateStart = timeSlot.dateStart;
    const dateEnd = timeSlot.dateEnd;

    const response = await this.$store.dispatch('meetingsStore/requestMeeting', {
      event_id: this.eventId,
      user_id: this.userId,
      date_start: DateTimeHelper.dateToApiDate(dateStart),
      date_end: DateTimeHelper.dateToApiDate(dateEnd),
    });

    if (response.status !== 200 || response.error) {
      timeSlot.errors = timeSlot.errors || [];
      timeSlot.errors = [...timeSlot.errors, { text: response.error ? response.error : this.$t('errors.meetingGeneralError') }];
      const timeSlotIndex = this.timeSlots.indexOf(timeSlot);
      this.timeSlots[timeSlotIndex] = { ...timeSlot };
      this.timeSlots = [...this.timeSlots];
      return;
    }

    if (response && response.data && response.data.status === MeetingStatus.Confirmed) {
      timeSlot.meeting = response.data;
    }
  }

  public clearMeetingErrors(timeSlot: TTimeSlot, index: number): void {
    timeSlot.errors = [];
    this.timeSlots[index] = { ...timeSlot };
    this.timeSlots = [...this.timeSlots];
  }

  public async markSlotAvailable(timeSlot: TTimeSlot): Promise<void> {
    if (timeSlot.meeting && timeSlot.meeting.id) {
      await this.$store.dispatch('meetingsStore/cancelMeeting', {
        event_id: this.eventId,
        meeting_id: timeSlot.meeting.id,
      });
      timeSlot.meeting = null;
    }
  }

  public isTimeSlotAvailable(timeSlot: TTimeSlot): boolean {
    return !(timeSlot && timeSlot.meeting);
  }

  public isEventDates(timeSlot: TTimeSlot): boolean {
    return +this.event.date_start <= +timeSlot.dateStart && +this.event.date_end >= +timeSlot.dateEnd;
  }

  public isTimeSlotPast(timeSlot: TTimeSlot): boolean {
    return timeSlot.dateEnd.getTime() < this.timeSlotsPastTimeMark;
  }

  public isHideButton(timeSlot: TTimeSlot): boolean {
    return (!timeSlot.meeting || timeSlot.meeting.contact.user.id === this.userId);
  }

  public isTimeSlotBusy(timeSlot: TTimeSlot): boolean {
    return !!(timeSlot && timeSlot.meeting);
  }

  public isTimeSlotDisabled(timeSlot: TTimeSlot): boolean {
    return (timeSlot.meeting && timeSlot.meeting.contact.user.id === this.userId);
  }

  public getMeetingShareUrl(meeting: TMeeting): string {
    return MeetingsHelper.getMeetingInviteUrl({
      type: MeetingRoomType.MEETING,
      eventId: this.eventId,
      meetingId: meeting.id,
      meetingDate: this.$moment(meeting.date_start).unix(),
    });
  }

  public messageContact(meeting: TMeeting): void {
    const contactId = meeting.contact.id;

    this.openContactCard({
      contactId,
      startupTabName: 'messages',
    });
  }

  public onVideoCallClick(meeting: TMeeting): void {
    const nowTimestamp = (new Date()).getTime();
    if (meeting.date_start.getTime() - nowTimestamp > (1000 * 60 * 5)) {
      this.$store.dispatch('meetingsStore/setMeetingTooEarlyPopupVisible', true);
      return;
    }
    const meetingRoomConfig = {
      type: 'meeting',
      eventId: this.eventId,
      meetingId: meeting.id,
      contactId: this.contactId,
      meetingDate: this.$moment(meeting.date_start).unix(),
    };
    this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
    this.dispatchWaitingMeetingNotification(meeting);
  }

  public dispatchWaitingMeetingNotification(meeting: TMeeting): void {
    if (meeting && meeting.contact && meeting.contact.id) {
      const context: any = {
        type: 'meeting-is-waiting',
        eventId: this.eventId,
        meetingId: meeting.id || undefined,
        meetingDate: this.$moment(meeting.date_start).unix(),
        moderatorContactId: this.contactId || undefined,
        externalId: undefined,
      };
      eventDiscoveryService.notifyAboutWaitingMeeting(meeting.contact.id, context);
    }
  }

  public onMeetingShareClick(event: PointerEvent, meeting: TMeeting): void {
    const newUrlToShare: string = this.getMeetingShareUrl(meeting);
    if (this.urlToShare === newUrlToShare) {
      this.closeSharer();
      return;
    }
    this.openSharer({
      eventTarget: event.target as Element,
      url: newUrlToShare,
    });
  }

  public onMeetingCanceled(): void {
    this.timeSlotForDotsMenu.meeting = null;
  }

  public showTime(date: Date): string {
    return DateTimeHelper.getHoursMinutes(date);
  }

  public onCalendarDaySelected(day: TScheduleDay): void {
    this.selectedDate = day.fullDate;
  }

  public showVideoCallAction(timeSlot: TTimeSlot): boolean {
    return timeSlot
      && !this.isTimeSlotDisabled(timeSlot)
      && this.isTimeSlotMeetingCurrent(timeSlot)
      && timeSlot.meeting.communication_type !== 'offline';
  }

  public indicatorType(timeSlot: TTimeSlot): string {
    if (timeSlot.meeting.communication_type !== 'offline') {
      return 'online';
    } else if (timeSlot.meeting.communication_type === 'offline' && !this.isTimeSlotPast(timeSlot)) {
      return 'offline';
    } else {
      return 'expired';
    }
  }

  public onDotsMenuClick(event: PointerEvent, timeSlot: TTimeSlot): void {
    this.showTimeSlotMenu({
      eventTarget: (event.target as HTMLElement)
    });
    this.timeSlotForDotsMenu = timeSlot;
  }

}

