


import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TMeeting, TTimeSlot} from '@/_types/meeting/meeting.type';
import TimeSlotMenuItem, {
  TimeSlotMenuItemImportedIcons
} from '@/_modules/meetings/components/time-slot-menu-item/time-slot-menu-item.vue';
import {TContact} from '@/_types/contact.type';
import {TUpdateMeetingContactFavoriteParams} from '@/_modules/meetings/store/meetings.store';
import MeetingsHelper from '@/_helpers/meetings.helper';
import {MeetingRoomType} from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import {DateTimeFormat} from '@/_types/date-time-format.enum';
import FileHelper from '@/_helpers/file.helper';
import * as ics from 'ics';
import {EventAttributes} from 'ics';
import {TabNames} from '@/_modules/promo/types/side-bar-right-store-state.type';

const APP_NAME = process.env.VUE_APP_NAME;

export type TTimeSlotMenuItem = {
  name: string;
  title: string;
  isSubmenuOpen?: boolean;
  iconName?: TimeSlotMenuItemImportedIcons;
  children?: TTimeSlotMenuItem[];
  isSeparator?: boolean;
}

@Component({
  components: {
    QrcodeVue: (): any => import(/* webpackPrefetch: true */ 'qrcode.vue'),
    TimeSlotMenuItem,
  }
})
export default class TimeSlotMenu extends Vue {

  @Ref('timeSlotMenuReference') timeSlotMenuReference: HTMLElement;
  @Ref('copierInputReference') copierInputReference: HTMLInputElement;
  @Ref('copierSuccessReference') copierSuccessReference: HTMLElement;

  @Getter('meetingsStore/isTimeSlotMenuVisible') isTimeSlotMenuVisible: boolean;
  @Getter('meetingsStore/timeSlotMenuEventTarget') timeSlotMenuEventTarget: HTMLElement;
  @Getter('promoPageStore/contact') contact: TContact;
  @Getter('meetingsStore/isMeetingCancellationConfirmed') isMeetingCancellationConfirmed: boolean;

  @Action('meetingsStore/hideTimeSlotMenu') hideTimeSlotMenu: () => void;
  @Action('meetingsStore/updateContactFavorite') updateMeetingStoreContactFavorite: (payload: TUpdateMeetingContactFavoriteParams) => Promise<void>;
  @Action('contactsStore/addFavContact') addFavContact: (params: { contactId: number }) => Promise<void>;
  @Action('contactsStore/removeFavContact') removeFavContact: (params: { contactId: number }) => Promise<void>;
  @Action('sideBarRightStore/setActiveTab') setSideBarRightActiveTab: (tabName: TabNames) => void;
  @Action('sideBarRightStore/open') openRightSideBar: () => void;
  @Action('meetingsStore/setMeetingInviteUrlForChatMessage') setMeetingInviteUrlForChatMessage: (url: string) => void;
  @Action('meetingsStore/cleanMeetingInviteUrlForChatMessage') cleanMeetingInviteUrlForChatMessage: () => void;
  @Action('meetingsStore/setMeetingCancelPopupVisible') setMeetingCancelPopupVisible: (isVisible: boolean) => void;
  @Action('meetingsStore/setMeetingCancellationConfirmed') setMeetingCancellationConfirmed: (value: boolean) => void;

  public recursionDepth: number = 0;
  public maxRecursionDepth: number = 32;
  public ignoreClickOutside: boolean = false;
  public isWechatBoxVisible: boolean = false;
  public top: string = '47%';
  public left: string = '40%';
  public menuItems: TTimeSlotMenuItem[] = [
    {
      name: 'shareMeeting',
      title: this.$t('timeSlotMenu.shareMeetingLink') as string,
      isSubmenuOpen: false,
      iconName: TimeSlotMenuItemImportedIcons.SHARE,
      children: [
        {
          name: 'copyLink',
          title: this.$t('timeSlotMenu.copyLink') as string,
          iconName: TimeSlotMenuItemImportedIcons.COPY_MEETING_LINK,
        },
        {
          name: 'sendAsMessage',
          title: this.$t('timeSlotMenu.sendAsMessage') as string,
          iconName: TimeSlotMenuItemImportedIcons.SEND_MEETING_AS_MESSAGE,
        },
        {
          name: 'separatorSocial',
          title: '',
          isSeparator: true,
        },
        {
          name: 'socShareWechat',
          title: this.$t('timeSlotMenu.wechatTitle') as string,
          iconName: TimeSlotMenuItemImportedIcons.WECHAT,
        },
        {
          name: 'socShareVK',
          title: 'VK',
          iconName: TimeSlotMenuItemImportedIcons.VK,
        },
        {
          name: 'socShareLinkedIn',
          title: 'LinkedIn',
          iconName: TimeSlotMenuItemImportedIcons.LINKEDIN,
        },
        {
          name: 'socShareFacebook',
          title: 'Facebook',
          iconName: TimeSlotMenuItemImportedIcons.FACEBOOK,
        },
      ]
    },
    {
      name: 'addToCalendars',
      title: this.$t('timeSlotMenu.exportMeetingTo') as string,
      isSubmenuOpen: false,
      iconName: TimeSlotMenuItemImportedIcons.ADD_TO_CALENDARS,
      children: [
        {
          name: 'appleCalendar',
          title: 'Apple calendar',
          iconName: TimeSlotMenuItemImportedIcons.APPLE_CALENDAR,
        },
        {
          name: 'googleCalendar',
          title: 'Google calendar',
          iconName: TimeSlotMenuItemImportedIcons.GOOGLE_CALENDAR,
        },
        {
          name: 'outlookCalendar',
          title: 'Outlook calendar',
          iconName: TimeSlotMenuItemImportedIcons.WINDOWS_CALENDAR,
        },
      ]
    },
    {
      name: 'cancelMeeting',
      title: this.$t('timeSlotMenu.cancelMeeting') as string,
      isSubmenuOpen: false,
      iconName: TimeSlotMenuItemImportedIcons.CANCEL_MEETING,
    },
    {
      name: 'separator-1',
      title: '',
      isSeparator: true,
    },
    {
      name: 'favContact',
      title: this.$t('timeSlotMenu.addContactToFavorites') as string, // N.B.: see getItemTitle() below
      iconName: TimeSlotMenuItemImportedIcons.ADD_CONTACT_TO_FAV, // N.B.: see getIconName() below
    },
  ];

  @Prop({
    type: Object,
    default: (): TTimeSlot => {
      return null;
    }
  })
  public readonly timeSlot: TTimeSlot;

  @Watch('isTimeSlotMenuVisible', { immediate: true })
  public onIsTimeSlotMenuVisibleChange(newVal: boolean): void {
    this.temporaryIgnoreClickOutside();
    this.initFavoriteContactMenuItem();
    if (!newVal) {
      this.hideAllSubmenus();
      this.hideWechatOverlay();
    }
  }

  @Watch('timeSlotMeetingId')
  public onTimeSlotMeetingIdChange(newVal: number): void {
    if (!newVal || !this.timeSlotMenuEventTarget) {
      return;
    }
    this.initFavoriteContactMenuItem();
    this.hideAllSubmenus();
    this.hideWechatOverlay();
    this.repositionMenu();
  }

  @Watch('isMeetingCancellationConfirmed')
  private onCancelMeetingChanged(): void {
    if (this.isMeetingCancellationConfirmed) {
      this.cancelMeeting();
    }
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get contactId(): number {
    return (this.contact && this.contact.id) || null;
  }

  public get timeSlotMeeting(): TMeeting {
    return (this.timeSlot && this.timeSlot.meeting) || null;
  }

  public get timeSlotMeetingId(): number {
    return (this.timeSlotMeeting && this.timeSlotMeeting.id) || 0;
  }

  public get timeSlotMeetingContact(): TContact {
    return (this.timeSlotMeeting && this.timeSlotMeeting.contact) || null;
  }

  public get meetingInviteUrl(): string {
    if (!this.timeSlotMeeting) {
      return '';
    }

    return MeetingsHelper.getMeetingInviteUrl({
      type: MeetingRoomType.MEETING,
      eventId: this.eventId,
      meetingId: this.timeSlotMeeting.id,
      meetingDate: this.$moment(this.timeSlotMeeting.date_start).unix(),
    });
  }

  public mounted(): void {
    document.removeEventListener('click', this.clickOutside);
    document.addEventListener('click', this.clickOutside);
  }

  public beforeDestroy(): void {
    document.removeEventListener('click', this.clickOutside);
  }

  public temporaryIgnoreClickOutside(): void {
    this.ignoreClickOutside = true;
    setTimeout(() => {
      this.ignoreClickOutside = false;
    }, 200);
  }

  public clickOutside(event: MouseEvent): void {
    if (this.ignoreClickOutside || !this.isTimeSlotMenuVisible) {
      return;
    }

    let isChildOfMenu = false;
    let el = event.target as HTMLElement;

    if (!el || !el.parentNode) {
      return;
    }

    while (el.parentNode
      && (el.parentNode as HTMLElement).tagName
      && ((el.parentNode as HTMLElement).tagName.toUpperCase() !== 'BODY')
    ) {
      if ((el.parentNode as HTMLElement).classList.contains('time-slot-menu')) {
        isChildOfMenu = true;
        break;
      }
      el = el.parentNode as HTMLElement;
    }

    if (!(event.target as HTMLElement).classList.contains('time-slot-menu')
      && (isChildOfMenu === false)
    ) {
      this.hideTimeSlotMenu();
    }
  }

  public repositionMenu(): void {
    const targetRect: DOMRect = this.timeSlotMenuEventTarget.getBoundingClientRect();
    const timeSlotMenuRect: DOMRect = this.timeSlotMenuReference.getBoundingClientRect();

    const isMenuReady: boolean = timeSlotMenuRect.width > 0 || timeSlotMenuRect.height > 0;

    if (!isMenuReady) {
      if (this.recursionDepth >= this.maxRecursionDepth) {
        return;
      }
      this.$nextTick(() => {
        this.recursionDepth++;
        this.repositionMenu();
      });
      return;
    }

    let newTop: number = (window.scrollY + targetRect.top + targetRect.height + 10);
    const newLeft: number = (window.scrollX + targetRect.left + targetRect.width - timeSlotMenuRect.width + 8);

    const viewportHeight: number = window.innerHeight;

    const isFittingVertically: boolean = (newTop + timeSlotMenuRect.height <= viewportHeight);

    if (!isFittingVertically) {
      newTop = newTop - timeSlotMenuRect.height - targetRect.height;
    }

    this.top = newTop.toFixed(0) + 'px';
    this.left = newLeft.toFixed(0) + 'px';
  }

  public onClick(menuItem: TTimeSlotMenuItem): void {
    const menuItemName: string = menuItem.name;
    this.hideAllSubmenus();
    this.showSubmenuByMenuItem(menuItem);

    switch (menuItemName) {
      case 'shareMeeting':
        break;
      case 'copyLink':
        this.copyToClipBoard();
        break;
      case 'sendAsMessage':
        this.onSendAsMessageClick();
        break;
      case 'exportToCalendars':
        break;
      case 'cancelMeeting':
        this.onCancelMeetingClick();
        break;
      case 'favContact':
        this.onFavContactClick();
        break;
      case 'socShareWechat':
        this.onWechatClick();
        break;
      case 'socShareVK':
      case 'socShareLinkedIn':
      case 'socShareFacebook':
        this.onSocialShareClick(menuItem.name);
        break;
      case 'appleCalendar':
        this.onAppleCalendarExportClick();
        break;
      case 'googleCalendar':
        this.onGoogleCalendarExportClick();
        break;
      case 'outlookCalendar':
        this.onOutlookCalendarExportClick();
        break;
      default:
        break;
    }
  }

  public hideAllSubmenus(): void {
    this.menuItems.forEach(item => {
      if (item.isSubmenuOpen) {
        item.isSubmenuOpen = false;
      }
    });
  }

  public showSubmenuByMenuItem(menuItem: TTimeSlotMenuItem): void {
    if (menuItem.isSubmenuOpen === false) {
      menuItem.isSubmenuOpen = true;
    }
  }

  public onFavContactClick(): void {
    if (!this.timeSlotMeetingContact) {
      return;
    }
    const newIsFavValue = !this.timeSlotMeetingContact.is_favorite;
    const contactId: number = this.timeSlotMeetingContact.id;
    if (newIsFavValue) {
      this.addFavContact({ contactId });
    } else {
      this.removeFavContact({ contactId });
    }
    this.updateMeetingStoreContactFavorite({ contactId, isFavorite: newIsFavValue });
  }

  public onCancelMeetingClick(): void {
    this.hideTimeSlotMenu();
    this.setMeetingCancelPopupVisible(true);
  }

  public async cancelMeeting(): Promise<void> {
    await this.$store.dispatch('meetingsStore/cancelMeeting', {
      event_id: this.eventId,
      meeting_id: this.timeSlotMeetingId
    });
    this.setMeetingCancelPopupVisible(false);
    this.setMeetingCancellationConfirmed(false); // N.B.: needed because of bug in AW-3217
    this.$emit('meetingCanceled'); // for visual feedback
  }

  public getIconName(menuItem: TTimeSlotMenuItem): TimeSlotMenuItemImportedIcons {
    switch (menuItem.name) {
      case 'favContact':
        return this.getFavContactIconName();
      default:
        return menuItem.iconName || TimeSlotMenuItemImportedIcons._NOTHING_;
    }
  }

  public getItemTitle(menuItem: TTimeSlotMenuItem): string {
    switch (menuItem.name) {
      case 'favContact':
        return this.getFavContactItemTitle();
      default:
        return menuItem.title;
    }
  }

  public getFavContactIconName(): TimeSlotMenuItemImportedIcons {
    if (!this.timeSlotMeetingContact || !this.timeSlotMeetingContact.is_favorite) {
      return TimeSlotMenuItemImportedIcons.ADD_CONTACT_TO_FAV;
    }
    return TimeSlotMenuItemImportedIcons.REMOVE_CONTACT_FROM_FAV;
  }

  public getFavContactItemTitle(): string {
    if (!this.timeSlotMeetingContact || !this.timeSlotMeetingContact.is_favorite) {
      return this.$t('timeSlotMenu.addContactToFavorites') as string;
    }
    return this.$t('timeSlotMenu.removeContactFromFavorites') as string;
  }

  public initFavoriteContactMenuItem(): void {
    const favContactMenuItemIndex = this.menuItems.findIndex(item => item.name === 'favContact');
    if (favContactMenuItemIndex < 0) {
      return;
    }
    this.menuItems[favContactMenuItemIndex] = {
      ...this.menuItems[favContactMenuItemIndex],
      iconName: this.getFavContactIconName(),
    };
  }

  public onSocialShareClick(socNetworkName: string): void {

    if (!this.meetingInviteUrl) {
      return;
    }

    const openSharer = (url: string, w: number, h: number, windowName: string): void => {
      const top: number = (screen.height > h ? (screen.height / 3 - h / 2) : 0);
      const left: number = Math.round(screen.width / 2 - w / 2);
      const windowRef: Window = window.open(url, windowName, 'left=' + left + ',top=' + top + ',width=' + w + ',height=' + h + ',personalbar=0,toolbar=0,scrollbars=1,resizable=1');
      windowRef.focus();
    };

    const prepareURL = (): string => {
      const dict: {[key: string]: string} = {
        socShareFacebook: 'https://www.facebook.com/sharer/sharer.php?u=[url]',
        // twitter: 'https://twitter.com/intent/tweet?url=[url]&text=[text]&via=eventswallet',// TODO: в параметре via должен быть твиттер-аккаунт, а у нас его нет
        socShareVK: 'https://vk.com/share.php?url=[url]',
        socShareLinkedIn: 'https://www.linkedin.com/shareArticle?mini=true&url=[url]',
      };

      if (socNetworkName && Object.prototype.hasOwnProperty.call(dict, socNetworkName)) {
        return dict[socNetworkName]
          .replace('[url]', encodeURIComponent(this.meetingInviteUrl));
      }

      return '';
    };

    const shareEntryPointUrl: string = prepareURL();
    if (shareEntryPointUrl) {
      openSharer(shareEntryPointUrl, 400, 500, 'sharerWindow');
    }

  }

  public onWechatClick(): void {
    if (!this.isWechatBoxVisible) {
      return this.showWechatOverlay();
    }
    this.hideWechatOverlay();
  }

  public showWechatOverlay(): void {
    this.isWechatBoxVisible = true;
  }

  public hideWechatOverlay(): void {
    this.isWechatBoxVisible = false;
  }

  public get calendarTitle(): string {
    if (!this.timeSlotMeeting) {
      return null;
    }
    const meeting = this.timeSlotMeeting;
    const contactId = this.contactId;
    if (meeting.creator_contact.id !== contactId) {
      return this.$t('[\'add-to-calendar\'].meeting.title', {
        name: (meeting.creator_contact && meeting.creator_contact.fullName) || 'noname',
      }) as string;
    } else {
      return this.$t('[\'add-to-calendar\'].meeting.title', {
        name: (meeting.user_contact && meeting.user_contact.fullName) || 'noname',
      }) as string;
    }
  }

  public get calendarDetails(): string {
    return this.$t('[\'add-to-calendar\'].meeting.details', {
      link: this.meetingInviteUrl,
    }) as string;
  }

  public onAppleCalendarExportClick(): void {
    this.exportIcs();
  }

  public onGoogleCalendarExportClick(): void {
    let url = this.getCalendarBaseUrl('googleCalendar');
    const params = this.getCalendarUrlParams('googleCalendar');
    if (!url || !params) {
      return null;
    }

    for (const key in params) {
      if (!key || !params[key]) {
        continue;
      }
      url += `&${key}=${encodeURIComponent(params[key]).replace(/%20/g, '+')}`;
    }

    this.imitateClick(url);

  }

  public imitateClick(url: string): void {
    const tempAnchor: HTMLAnchorElement = document.createElement('a');
    tempAnchor.setAttribute('href', url);
    tempAnchor.setAttribute('target', '_blank');
    document.getElementsByTagName('body')[0].appendChild(tempAnchor);
    tempAnchor.click();
    this.$nextTick(() => {
      tempAnchor.parentNode.removeChild(tempAnchor);
    });
  }

  public onOutlookCalendarExportClick(): void {
    this.exportIcs();
  }

  public getCalendarBaseUrl(calendarType: string): string {
    switch (calendarType) {
      case 'googleCalendar':
        return 'https://www.google.com/calendar/render?action=TEMPLATE&trp=false';

      case 'outlookCalendar':
        return 'https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent';
    }

    return null;
  }

  private getCalendarUrlParams(calendarType: string): { [key: string]: string } {

    switch (calendarType) {
      case 'googleCalendar': {
        return {
          text: this.calendarTitle,
          details: this.calendarDetails,
          location: this.calendarLocation,
          dates: this.getGoogleDateFormat(this.calendarStart) + '/' + this.getGoogleDateFormat(this.calendarEnd),
        };
      }

      case 'outlookCalendar': {
        return {
          startdt: this.getMicrosoftDateFormat(this.calendarStart),
          enddt: this.getMicrosoftDateFormat(this.calendarEnd),
          subject: this.calendarTitle,
          body: this.calendarDetails,
          location: this.calendarLocation,
        };
      }

    }

    return null;
  }

  public async exportIcs(): Promise<void> {
    const ics = await this.getIcs();
    if (!ics) {
      return;
    }
    const filename = (
      this.calendarTitle
      + ' ('
      + this.$moment(this.calendarStart).format(DateTimeFormat.DATE_MEDIUM)
      + ' - '
      + this.$moment(this.calendarEnd).format(DateTimeFormat.DATE_MEDIUM)
      + ')'
    );
    const fileName = `${filename}.ics`;
    FileHelper.downloadFile(new File([ ics ], fileName), fileName);
  }

  public get calendarLocation(): string {
    return this.$t('[\'add-to-calendar\'].meeting.location') as string;
  }

  private getGoogleDateFormat(date: Date): string {
    return this.$moment(date).utc(false).format('YYYYMMDDTHHmmss') + 'Z';
  }

  private getMicrosoftDateFormat(date: Date): string {
    return this.$moment(date).utc(false).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  }

  public get calendarStart(): Date {
    if (!this.timeSlotMeeting) {
      return null;
    }
    return this.$moment(this.timeSlotMeeting.date_start).toDate();
  }

  public get calendarEnd(): Date {
    if (!this.timeSlotMeeting) {
      return null;
    }
    return this.$moment(this.timeSlotMeeting.date_end).toDate();
  }

  private async getIcs(): Promise<string> {
    const startMoment = this.$moment(this.calendarStart);
    const endMoment = this.$moment(this.calendarEnd);
    const eventAttributes: EventAttributes = {
      productId: APP_NAME,
      start: [
        startMoment.year(),
        startMoment.month() + 1,
        startMoment.date(),
        startMoment.hours(),
        startMoment.minutes()
      ],
      end: [
        endMoment.year(),
        endMoment.month() + 1,
        endMoment.date(),
        endMoment.hours(),
        endMoment.minutes()
      ],
      startInputType: 'local',
      startOutputType: 'utc',
      endInputType: 'local',
      endOutputType: 'utc',
      title: this.calendarTitle,
      description: this.calendarDetails,
      location: this.calendarLocation,
    };
    const result = await ics.createEvent(eventAttributes);
    return (!!result.error || !result.value) ? null : result.value;
  }

  public copyToClipBoard(): boolean {
    this.copierInputReference.select();
    document.execCommand('copy');
    this.copierInputReference.blur();

    // visual feedback
    this.copierSuccessReference.classList.add('copylink-done');
    setTimeout(() => {
      this.copierSuccessReference.classList.remove('copylink-done');
    }, 3000);

    return false;
  }

  public onSendAsMessageClick(): void {
    this.setMeetingInviteUrlForChatMessage(this.meetingInviteUrl);
    this.setSideBarRightActiveTab(TabNames.CORRESPONDENCE);
    this.openRightSideBar();
  }

}
