


import { Vue, Component, Prop } from 'vue-property-decorator';
import IconTimeSlotHasSubmenu from '@/_modules/icons/components/icon-time-slot-has-submenu.vue';
import IconShareMeeting from '@/_modules/icons/components/icon-share-meeting.vue';
import IconExportMeeting from '@/_modules/icons/components/icon-export-meeting.vue';
import EwIconAcceptReject from '@/_modules/icons/components/meetings/ew-icon-accept-reject.vue';
import IconFavoriteMeetingContact from '@/_modules/icons/components/icon-favorite-meeting-contact.vue';
import IconUnfavoriteMeetingContact from '@/_modules/icons/components/icon-unfavorite-meeting-contact.vue';
import IconCopyMeetingLink from '@/_modules/icons/components/icon-copy-meeting-link.vue';
import IconMeetingAsMessage from '@/_modules/icons/components/icon-meeting-as-message.vue';
import IconMeetingWechat from '@/_modules/icons/components/icon-meeting-wechat.vue';
import IconMeetingVk from '@/_modules/icons/components/icon-meeting-vk.vue';
import IconMeetingLinkedin from '@/_modules/icons/components/icon-meeting-linkedin.vue';
import IconMeetingFacebook from '@/_modules/icons/components/icon-meeting-facebook.vue';
import IconMeetingAppleCalendar from '@/_modules/icons/components/icon-meeting-apple-calendar.vue';
import IconMeetingWindowsCalendar from '@/_modules/icons/components/icon-meeting-windows-calendar.vue';
import IconMeetingGoogleCalendar from '@/_modules/icons/components/icon-meeting-google-calendar.vue';

export enum TimeSlotMenuItemImportedIcons {
  _NOTHING_ = '',
  SHARE = 'icon-share-meeting',
  ADD_TO_CALENDARS = 'icon-export-meeting',
  CANCEL_MEETING = 'ew-icon-accept-reject',
  ADD_CONTACT_TO_FAV = 'icon-favorite-meeting-contact',
  REMOVE_CONTACT_FROM_FAV = 'icon-unfavorite-meeting-contact',
  COPY_MEETING_LINK = 'icon-copy-meeting-link',
  SEND_MEETING_AS_MESSAGE = 'icon-meeting-as-message',
  WECHAT = 'icon-meeting-wechat',
  VK = 'icon-meeting-vk',
  LINKEDIN = 'icon-meeting-linkedin',
  FACEBOOK = 'icon-meeting-facebook',
  APPLE_CALENDAR = 'icon-meeting-apple-calendar',
  GOOGLE_CALENDAR = 'icon-meeting-google-calendar',
  WINDOWS_CALENDAR = 'icon-meeting-windows-calendar',
}

@Component({
  components: {
    IconTimeSlotHasSubmenu,
    IconShareMeeting,
    IconExportMeeting,
    EwIconAcceptReject,
    IconFavoriteMeetingContact,
    IconUnfavoriteMeetingContact,
    IconCopyMeetingLink,
    IconMeetingAsMessage,
    IconMeetingWechat,
    IconMeetingVk,
    IconMeetingLinkedin,
    IconMeetingFacebook,
    IconMeetingAppleCalendar,
    IconMeetingGoogleCalendar,
    IconMeetingWindowsCalendar,
  }
})
export default class TimeSlotMenuItem extends Vue {

  @Prop({ type: String, default: '' })
  public readonly title: string;

  @Prop({ type: String, default: '' })
  public readonly iconName: TimeSlotMenuItemImportedIcons;

  @Prop({ type: Boolean, default: false })
  public readonly isSeparator: string;

  @Prop({ type: Boolean, default: false })
  public readonly hasSubmenu: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly isSubmenuOpen: boolean;

  public availableIconNames: typeof TimeSlotMenuItemImportedIcons = TimeSlotMenuItemImportedIcons;

  public get isIconVisible(): boolean {
    if (!this.iconName) {
      return false;
    }
    return !!Object.values(this.availableIconNames).find(availableIconName => availableIconName === this.iconName);
  }

  public onClick(): void {
    this.$emit('click');
  }

}
